import React from 'react';

import './app.scss';

function App() {
  return (
    <div className="app">
      <div className="note">
        <span className="note__text">Ждем тебя на </span>
        <a
          className="note__link"
          href="https://maffia-online.ru/"
          rel="noopener nofollow"
          title="Мафия Онлайн"
        >
          maffia-online.ru
        </a>
      </div>
    </div>
  );
}

export default App;
